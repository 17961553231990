<template>
  <div>
    <hooper ref="carousel" class="video-slider" :settings="hooperSettings" @slide="onSlide">
      <slide v-for="video in videos" :key="video.id" :data-video-id="video.omniaId" data-vr-contentbox>
        <div class="relative cursor-pointer" @click="startVideo(video)">
          <picture class="picture-wrapper">
            <source type="image/webp" :data-srcset="video.imageWebP" />
            <img class="h-auto block border-0 lazyload" width="630" height="355" :data-src="video.image" :alt="video.kicker" />
          </picture>

          <svg width="60" height="60" class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <use xlink:href="#video" />
          </svg>
        </div>

        <h3 class="leading-tight relative -mt-6 z-1 md:-mt-8 -ml-2px cursor-pointer" data-vr-excerpttitle @click="startVideo(video)">
          <div class="text-vertical inline-block bg-white h-7 sm:h-10 pt-2 pr-4 sm:pr-6 max-w-kicker truncate text-base md:text-xl pl-2px">
            {{ video.kicker }}
          </div>
          <div data-vr-headline class="font-bold text-md sm:text-xl md:text-5xl pl-2px max-lines max-lines-3">
            {{ video.title }}
          </div>
        </h3>
      </slide>
      <hooper-navigation slot="hooper-addons" />
      <hooper-pagination slot="hooper-addons" />
    </hooper>

    <video-app-component
      v-if="playVideo && currentVideo != null"
      :video-domain-id="currentVideo.safeDomainId"
      :video-id="currentVideo.omniaId.toString()"
      :auto-show-video-layer="true"
      large-button="false"
      hide-button="true"
      @video-closed="closeVideo" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { Hooper, Slide, Pagination as HooperPagination, Navigation as HooperNavigation } from 'hooper';
import VideoAppComponent from '@/components/video/videoApp.component.vue';

import TrackingUtils from '@/utils/tracking/tracking.utils';

export default defineComponent({
  name: 'video-slider',
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
    VideoAppComponent,
  },
  props: {
    videos: {
      type: Array,
    },
  },
  data() {
    return {
      currentVideo: null,
      playVideo: false,
      hooperSettings: {
        itemsToShow: 1.125,
        autoPlay: window.innerWidth > 767,
        infiniteScroll: true,
        playSpeed: 5000,
        wheelControl: false,
        breakpoints: {
          520: {
            itemsToShow: 1.48,
          },
          768: {
            itemsToShow: 1,
          },
        },
      },
      observer: null as null | IntersectionObserver,
    };
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      threshold: 0.1,
      rootMargin: '300px',
    });
    const target = document.querySelector('#videoSlider');
    if (target && window.innerWidth > 767) this.observer.observe(target);
  },
  mounted() {
    if (window.innerWidth > 767) {
      document.querySelectorAll('.hooper-indicators button').forEach((el) => {
        el.addEventListener('click', () => {
          TrackingUtils.trackEvent({
            eventCategory: 'video',
            eventAction: `slide_change_${(this.$refs.carousel as any).currentSlide + 1}`,
            eventLabel: 'videostage',
          });
        });
      });
    }
  },
  beforeDestroy() {
    if (this.observer) this.observer.disconnect();
  },
  methods: {
    onElementObserved(entries: IntersectionObserverEntry[]) {
      entries.forEach(({ target, isIntersecting }: any) => {
        if (!isIntersecting) {
          return;
        }
        (this.$refs.carousel as any).restartTimer();
        (this.$refs.carousel as any).slideTo(0);
        if (this.observer) this.observer.unobserve(target);
      });
    },
    onSlide(event?: { currentSlide: number; slideFrom: number }) {
      if (!event || event.slideFrom === null) return;
      if (window.innerWidth <= 767) {
        if (event.currentSlide < 0) {
          event.currentSlide = this.videos!.length - 1;
        } else if (event.currentSlide > this.videos!.length - 1) {
          event.currentSlide = 0;
        }
        const index = (event.currentSlide + 1).toString();
        TrackingUtils.trackEvent({
          eventCategory: 'video',
          eventAction: `slide_change_${index}`,
          eventLabel: 'videostage',
        });
      }
    },
    startVideo(video: any) {
      this.currentVideo = video;
      this.playVideo = true;
    },
    closeVideo() {
      this.currentVideo = null;
      this.playVideo = false;
    },
  },
});
</script>

<style scoped>
.picture-wrapper {
  padding-bottom: 56.39%;
  height: 0;
}

.hooper {
  height: auto;
  padding-bottom: 1.5rem;
  outline: none;
}

.hooper-pagination {
  bottom: -0.5rem;
}
</style>
<style>
.video-slider .hooper-slide {
  padding-right: 1rem;
}

@media (min-width: 768px) {
  .video-slider .hooper-slide {
    padding-right: 1.25rem;
  }
  .video-slider .is-next {
    padding-left: 1rem;
  }
}

.video-slider .hooper-indicator {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #d8d8d8;
  margin: 0 0.25rem;
  border-radius: 50%;
}

.video-slider .hooper-indicator.is-active {
  background-color: var(--text-base);
}

@media (min-width: 768px) {
  .video-slider .hooper-indicator {
    width: 0.625rem;
    height: 0.625rem;
  }
}

.video-slider .hooper-navigation {
  display: none;
}

@media (min-width: 768px) {
  .video-slider .hooper-navigation {
    display: block;
  }
}

.video-slider .hooper-next,
.video-slider .hooper-prev {
  top: 0;
  transform: none;
  height: 100%;
  width: 3.5rem;
  height: 68.5%;
}

.video-slider .hooper-next {
  right: 1.25rem;
}

.video-slider .hooper-navigation .icon {
  display: none;
}
</style>
